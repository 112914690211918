import { render, staticRenderFns } from "./DashboardHub.vue?vue&type=template&id=04a551a0&scoped=true"
import script from "./DashboardHub.vue?vue&type=script&lang=js"
export * from "./DashboardHub.vue?vue&type=script&lang=js"
import style0 from "./DashboardHub.vue?vue&type=style&index=0&id=04a551a0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a551a0",
  null
  
)

export default component.exports