<template>
    <div style="display: flex;">
        <div class="mainGrid">
            <div class="grid" id="content">
                <grid-layout :class="showGrid ? 'grid gridLines' : 'grid'"
                    ref="gridLayout"
                    id="grid"
                    :layout="layout"
                    :col-num="25"
                    :row-height="getRowHeight()"
                    :max-rows="14"
                    :responsive="false"
                    :vertical-compact="false"
                    :is-draggable="true"
                    :is-resizable="true"
                    :is-mirrored="false"
                    :margin="[0, 0]"
                    :use-css-transforms="true"
                    :key="updateGridKey"
                    >
                    <grid-item
                        v-for="(item, index) in layout" 
                        class="widget"
                        :id="widget.path"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        :key="updateGridItem + index"
                        :isResizable="widget.isResizable" 
                        :preserve-aspect-ratio="widget.scaleToAR"
                        :minW="widget.minW"
                        :maxW="widget.maxW"
                        :minH="widget.minH"
                        :maxH="widget.maxH"
                        drag-ignore-from=".no-drag"
                        @moved="logPosition"
                        >
                        <WidgetImport :widget="widget.path" :w="widget.w" :h="widget.h" :filters="filterObj" :key="filterKey" :widgetIndex="index" :id="`${widget.path + '-' + index}`"/>
                    </grid-item>
                </grid-layout>
            </div>
            <div class="right" v-if="viewRight">
                <div v-if="currentTab == 'Widgets'" class="rightSection">
                    <v-toolbar dense flat>
                        <v-toolbar-title>
                            Widgets
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                        <div style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                            <div style="width: 100%; padding: 5px 0 5px 10px; display: flex; flex-direction: column;">
                                <span class="widgetTitle">New Widget</span>
                                <span class="widgetSubtitle">Click to add a new Widget</span>
                            </div>
                            <v-card :height="(5 * 1.8) + 'rem'" :width="(10 * 1.8) + 'rem'" class="templateCard" style="margin: 5px 0 15px 0; contain: content; border-radius: 1px solid #ffffff52;" @click="addGridItem(template)" elevation="20">
                                <v-icon style="position: absolute; scale: 5; opacity: 0.5;">add_circle_outline</v-icon>

                            </v-card>
                        </div>
                        <div v-for="(widget, index) in widgets" :key="index" style="display: flex; flex-direction: column; align-items: center; width: 100%; padding: 0 5px 0 5px">
                            <div style="width: 100%; padding: 5px; display: flex; flex-direction: column;">
                                <span class="widgetTitle">{{widget.title}} <v-icon small v-if="widget.readyForPublish" color="edit" style="margin-bottom: 2px;">verified</v-icon> </span>
                                <span class="widgetSubtitle">{{'@widgets/' + widget.path + '.vue'}}</span>
                            </div>
                            <v-card :height="widget.imageSigned ? 'fit-content' : ((widget.h * 1.7) + 'rem')" :width="((widget.w * 1.7) + 'rem')" class="templateCard" style="margin: 5px 0 15px 0; contain: content"
                            @drag="drag(widget)" @dragend="dragend(widget)" draggable="true" @click="addWidgetItem(widget)" elevation="20"
                            >
                            <v-img :src="widget.imageSigned" v-if="widget.imageSigned"></v-img> 
                            <b v-else>{{ widget.h + ' x ' + widget.w}}</b>
                        </v-card>
                    </div>
                </div>
                <div v-if="currentTab == 'Settings'" class="rightSection">
                    <v-toolbar dense flat>
                        <v-toolbar-title>
                            Settings
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <div class="d-flex justify-center align-center px-4">
                        <v-card :class="widgetImage ? 'photoClass' : 'uploadPhoto'" @click="openFileExplorer('imageUpload')" >
                            <input
                                  id="imageUpload"
                                  type="file"
                                  hidden
                                  accept="image/jpeg, image/png"
                                  @change="onFileChanged($event, 'imageUpload')"
                                />
                            <v-icon v-if="!widgetImage && !widget.imageSigned" x-large>add_photo_alternate</v-icon>
                            <v-img v-if="widgetImage" :src="widgetImage"></v-img>
                            <v-img v-if="widget.imageSigned && !widgetImage" :src="widget.imageSigned"></v-img>
                        </v-card>
                    </div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">title</v-icon>Title</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                The title of the widget to be displayed to the users.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <div style="width: 100%;" class="px-5">
                        <v-text-field dense hide-details outlined v-model="widget.title" @change="storeChanges"></v-text-field>
                    </div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">description</v-icon>Description</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                A brief description of the purpose of the widget for the user.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <div style="width: 100%;" class="px-5">
                        <v-textarea dense outlined counter rows="1" auto-grow maxLength="125" v-model="widget.description" @change="storeChanges" class="textAreaAdjust">
                            <template v-slot:counter="{props}">
                                <span>{{ props.value + ' / 125' }}</span>
                            </template>
                        </v-textarea>
                    </div>
                    <v-list-item class="pt-0">
                        <v-list-item-content class="pt-0">
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">link</v-icon>Path</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                The name of the <b>Vue</b> file found in: <br> <b>@components/Dashboard/widgets</b>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <div style="width: 100%;" class="px-5">
                        <v-text-field prefix="@widgets/" suffix=".vue" dense hide-details  outlined v-model="widget.path" @change="storeChanges"></v-text-field>
                    </div>
                    
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">local_offer</v-icon>Tags</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                Tags to help categorize the widget.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <div style="width: 100%;" class="px-5">
                        <v-select :loading="loadingTagsScopes" multiple chips hide-details dense outlined v-model="widget.tags" @change="storeChanges" :items="tags" item-text="name" item-value="id"></v-select>
                        <!-- <v-text-field hide-details dense outlined v-model="widget.description" @change="storeChanges"></v-text-field> -->
                    </div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">vpn_key</v-icon>Scopes</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                <b>If this widget is to be available to all users, then don't select any scopes.</b><br>
                                This determines the availability of the widget based on User Access, Subscriptions etc.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <div style="width: 100%;" class="px-5">
                        <v-select :loading="loadingTagsScopes" multiple chips hide-details dense outlined v-model="widget.scopes" @change="storeChanges" :items="scopes" item-text="name" item-value="identifier">
                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" v-bind="attrs">
                                    <v-list-item-avatar>
                                        <v-checkbox hide-details dense :value="widget.scopes.find(x => x == item.id)"></v-checkbox>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{item.description}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        
                        </v-select>
                        <!-- <v-text-field hide-details dense outlined v-model="widget.description" @change="storeChanges"></v-text-field> -->
                    </div>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;" >photo_size_select_small</v-icon>Resizable</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                If 'false', the widget will not be resizable by the user.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch dense v-model="widget.isResizable" @change="storeChanges"></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item :disabled="widget.isResizable == false">
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">aspect_ratio</v-icon>Preserve Aspect Ratio</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                If 'true', forces the widget to preserve its aspect ratio when resizing.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch :disabled="!widget.isResizable" dense v-model="widget.scaleToAR" @change="preserveAspect"></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item :disabled="widget.isResizable == false">
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small  style="rotate: 90deg; margin-bottom: 2px">expand</v-icon>Min/Max Width</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                Stops the user from scaling the widget larger than the specified grid width.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch :disabled="!widget.isResizable" dense v-model="widget.minMaxWidth" @change="storeChanges"></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                    <v-expand-transition>
                        <div style="width: 100%" class="px-5" v-if="widget.minMaxWidth && widget.isResizable" >
                            <v-range-slider
                                :disabled="!widget.isResizable"
                                class="my-5"
                                hide-details
                                v-model="widget.widthRange"
                                max="25"
                                min="1"
                                @change="storeChanges"
                            >
                            <template v-slot:thumb-label="props">
                                    {{ props.value }}
                            </template>
                        </v-range-slider>
                        </div>
                    </v-expand-transition>
                    <v-list-item :disabled="widget.isResizable == false">
                        <v-list-item-content>
                            <v-list-item-title><v-icon left small style="margin-bottom: 2px;">expand</v-icon>Min/Max Height</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                Stops the user from scaling the widget smaller than the specified grid height.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-switch :disabled="!widget.isResizable" dense v-model="widget.minMaxHeight" @change="storeChanges"></v-switch>
                        </v-list-item-action>
                    </v-list-item>
                    <v-expand-transition>
                        <div style="width: 100%" class="px-5" v-if="widget.minMaxHeight && widget.isResizable" >
                            <v-range-slider
                                :disabled="!widget.isResizable"
                                class="my-5"
                                hide-details
                                v-model="widget.heightRange"
                                max="14"
                                min="1"
                                @change="storeChanges"
                            >
                            <template v-slot:thumb-label="props">
                                    {{ props.value }}
                            </template>
                        </v-range-slider>
                        </div>
                    </v-expand-transition>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-icon left small style="margin-bottom: 2px;">verified</v-icon>Production Ready</v-list-item-title>
                            <v-list-item-subtitle class="description">
                                <b>ONLY CHECK THIS IF YOUR CODE IS ALREADY IN PRODUCTION!</b><br>
                                The widgets are stored in S3, so as soon as you check this, the widget will be available live on production.
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-checkbox dense v-model="widget.readyForPublish" @change="storeChanges"></v-checkbox>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider class="mb-3"></v-divider>
                    <v-row>
                        <v-col class="d-flex justify-center align-center">
                            <v-btn :disabled="cantSave" v-if="!loading" @click="widget.createdAt ? updateWidget() : createWidgetSteps()" color="edit">{{widget.createdAt ? 'Update Widget' :'Create Widget'}}</v-btn>
                            <v-progress-circular v-if="loading" indeterminate size="24"></v-progress-circular>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </div>
        <div class="rightBar">
            <div class="navIcons">
                <div v-for="(tab, i) in rightTabs.filter(x => x.show)" :key="i">
                    <v-tooltip left open-delay="0" close-delay="0" transition="none">
                        <template v-slot:activator="{on: on}">
                            <v-btn plain class="pa-0 mt-1" v-on="on" small fab @click="viewRightBar(tab.name)">
                                <v-icon :color="tab.name == currentTab ? '#538fc7' : '#bbbbbb'">{{tab.icon}}</v-icon>
                            </v-btn>
                        </template>
                        {{tab.name}}
                    </v-tooltip>
                </div>
            </div>
        </div>
        <v-dialog v-model="createWidgetModal" width="50%">
            <WidgetStepsModal :widget="widget" @createWidget="createWidget"/>
        </v-dialog>

    </div>
</template>

<script>
import VueGridLayout from 'vue-grid-layout';
import WidgetImport from './widgetUtils/WidgetImports.vue'
import WidgetStepsModal from './widgetUtils/WidgetStepsModal.vue'
let mouseXY = {"x": null, "y": null};
export default {
    props: ['newWidget', 'filter'],
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        WidgetImport,
        WidgetStepsModal
    },
    data: () => ({
        currentTab: '',
        widgets: [],
        cantSave: true,
        filterObj: {},
        filterKey: 67,
        loading: false,
        imageUpload: null,
        widgetImage: null,
        imageKey: 298,
        tags: [],
        scopes: [],
        loadingTagsScopes: false,
        widget: {
            title: '',
            path: '',
            isResizable: true,
            scaleToAR: false,
            heightRange: [1, 14],
            widthRange: [1, 25],
            minMaxWidth: false,
            minMaxHeight: false,
            minW: 1,
            maxW: 25,
            minH: 1,
            maxH: 14,
            w: 1,
            h: 1,
            scopes: [],
            tags: []
        },
        settings: {
            title: '',
            path: '',
            isResizable: true,
            scaleToAR: false,
            heightRange: [1, 14],
            widthRange: [1, 25],
            minMaxWidth: false,
            minMaxHeight: false,
            minW: 1,
            maxW: 25,
            minH: 1,
            maxH: 14,
        },
        layout: [],
        showGrid: true,
        viewRight: false,
        createWidgetModal: false,
        layoutCache: [],
        updateGridKey: 0,
        updateGridItem: 0,
        rightTabs: [
            {name: 'Widgets', icon: 'widgets', show: true},
            {name: 'Settings', icon: 'tune', show: true},
        ],
        template: {"x":5,"y":0,"w":10,"h":5,"i": '1'},
    }),
    created() {
        this.getRowHeight()
        this.getWidgets()
        this.getTagsAndScopes()

    },
    mounted() {
        document.addEventListener("dragover", function (e) {
            mouseXY.x = e.clientX;
            mouseXY.y = e.clientY;
        }, false);
        sessionStorage.getItem('widget') ? this.widget = JSON.parse(sessionStorage.getItem('widget')) : null
        sessionStorage.getItem('settings') ? this.settings = JSON.parse(sessionStorage.getItem('settings')) : null
        sessionStorage.getItem('currentTab') ? this.currentTab = sessionStorage.getItem('currentTab') : null
        sessionStorage.getItem('viewRight') ? this.viewRight = JSON.parse(sessionStorage.getItem('viewRight')) : null
        sessionStorage.getItem('layout') ? this.layout = JSON.parse(sessionStorage.getItem('layout')) : null
        sessionStorage.getItem('createWidgetModal') ? this.createWidgetModal = JSON.parse(sessionStorage.getItem('createWidgetModal')) : null
    },
    watch: {
        "widget.path" : {
            immediate: true,
            handler(val) {
                this.checkSave()
            }
        },
        "widget.title": {
            immediate: true,
            handler(val) {
                this.checkSave()
            }
        },
        "layout" : {
            immediate: true,
            handler(val) {
                this.checkSave()
            }
        },
        "filter": {
            immediate: true,
            handler(val) {
                this.filterObj = JSON.parse(JSON.stringify(val))
                this.filterKey++
            }
        },
        "widget.widthRange": {
            immediate: true,
            handler(val) {
                if(this.widget.widthRange[0] > this.widget.widthRange[1]) {
                    this.widget.minW = this.widget.widthRange[1]
                    this.widget.maxW = this.widget.widthRange[0]
                } else {
                    this.widget.minW = this.widget.widthRange[0]
                    this.widget.maxW = this.widget.widthRange[1]
                }
            }
        },
        "widget.heightRange": {
            immediate: true,
            handler(val) {
                if(this.widget.heightRange[0] > this.widget.heightRange[1]) {
                    this.widget.minH = this.widget.heightRange[1]
                    this.widget.maxH = this.widget.heightRange[0]
                } else {
                    this.widget.minH = this.widget.heightRange[0]
                    this.widget.maxH = this.widget.heightRange[1]
                }
            }
        }
    },
    methods: {
        logPosition() {
            console.log(this.layout)
        },
        async getTagsAndScopes() {
            this.loadingTagsScopes = true
            let result = await this.$API.getTagsAndScopes()
            if(result) {
                this.tags = result.tags
                this.scopes = result.scopes
            }
            this.loadingTagsScopes = false
        },
        storeChanges() {
            sessionStorage.setItem('widget', JSON.stringify(this.widget))
            sessionStorage.setItem('settings', JSON.stringify(this.settings))
            sessionStorage.setItem('currentTab', this.currentTab)
            sessionStorage.setItem('viewRight', this.viewRight)
            sessionStorage.setItem('layout', JSON.stringify(this.layout))
            sessionStorage.setItem('createWidgetModal', this.createWidgetModal)
            sessionStorage.setItem('tab', 'Development')
            console.log('stored')
        },
        async getWidgets() {
            let result = await this.$API.getWidgets();
            this.widgets = result
        },
        createWidgetSteps() {
            this.createWidgetModal = true
            this.storeChanges()
        },
        removePtyLtd(name) {
            const regex = /\s*\([^)]*\)\s*|\s*\(pty\s*ltd\)\s*|\s*pty\s*ltd\s*|\s*limited\s*|\s*ltd\s*/gi;

            let final = name.replace(regex, '').trim();
            if (final.includes('()')) {
                final.replace('()', '');
            }
            return final;
        },
        async createWidget() {
            this.loading = true
            let obj = {
                ...this.widget,
                w: this.layout[0].w ? this.layout[0].w : this.widget.minW,
                h: this.layout[0].h > 0 ? this.layout[0].h : this.widget.minH,
                createdBy: this.$store.state.firstname,
                userId: this.$store.state.userId
            }
            if(this.imageUpload) {
                let image = await this.photoUpload(this.imageUpload, this.widget.title.replace(/ /g, '_'))
                if(image) {
                    obj.image = image
                }
            }
            let result = await this.$API.createWidget(obj);
            console.log(result)
            this.createWidgetModal = false
            this.clearSessionStorage()
            this.widget = {...this.settings}
            this.loading = false

        },
        async updateWidget() {
            this.loading = true
            let obj = {
                ...this.widget,
                w: this.layout[0].w ? this.layout[0].w : this.widget.minW,
                h: this.layout[0].h > 0 ? this.layout[0].h : this.widget.minH,
                updatedAt: new Date(),
                updatedBy: this.$store.state.firstname,
                userId: this.$store.state.userId,
            }
            if(this.imageUpload) {
                let image = await this.photoUpload(this.imageUpload, this.widget.title.replace(/ /g, '_'))
                if(image) {
                    obj.image = image
                }
            }
            let result = await this.$API.updateWidget(obj);
            if(result) {
            this.$message.success('Widget Updated Successfully')
            console.log(result)
            this.clearSessionStorage()
            this.widget = {...this.settings}
            } else {
                this.$message.error('Error Updating Widget')
            }
            this.loading = false

        },
        checkSave() {
            // if this.widget.path or this.widget.title is an empty string or null, disable the save button
            // if this.layout is an empty array, disable the save button
            if(this.widget.path && this.widget.title && this.layout.length > 0 && this.widget.description) {
                this.cantSave = false
            } else {
                this.cantSave = true
            }
        },
        clearSessionStorage() {
            sessionStorage.removeItem('widget')
            sessionStorage.removeItem('settings')
            sessionStorage.removeItem('layout')
            sessionStorage.removeItem('tab')
            sessionStorage.removeItem('createWidgetModal')
        },
        getRowHeight() {
            let rowHeight = (window.innerHeight - 50) / 14;
            // convert to integer
            rowHeight = parseFloat(rowHeight.toFixed(2))
            return rowHeight;
        },
        viewRightBar(tabName) {
            if(tabName == this.currentTab) {
                this.currentTab = ''
                this.viewRight = false
            } else {
                this.currentTab = tabName
                this.viewRight = true
            }
        },
        addGridItem(template) {
            const xPos = parseInt(((Math.floor(25 - template.w) / 2)))
            const yPos = parseInt(((Math.floor(14 - template.h) / 2)))
            this.layout = [{
                ...this.settings,
                x: xPos,
                y: yPos,
                w: template.w,
                h: template.h,
                i: template.i,
            }]
            this.currentTab = 'Settings'
            // this.updateGridKey++
        },
        addWidgetItem(widget) {
            const xPos = parseInt(((Math.floor(25 - widget.w) / 2)))
            const yPos = parseInt(((Math.floor(14 - widget.h) / 2)))
            this.layout = [{
                x: xPos,
                y: yPos,
                w: widget.w,
                h: widget.h,
                i: widget.i,
            }]
            this.widget = widget
            this.currentTab = 'Settings'
        },

        drag(template) {
            let parentRect = document.getElementById('content').getBoundingClientRect();
            let mouseInGrid = false;
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true;
            }
            if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
                this.layoutCache = [...this.layout]
                this.layout = [{
                    x: (this.layout.length * 2) % (this.colNum || 12),
                    y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                    w: 1,
                    h: 1,
                    i: 'drop',
                }];
            }
            let index = this.layout.findIndex(item => item.i === 'drop');
            if (index !== -1) {
                    this.$refs.gridLayout.$children[this.layout.length].$refs.item.style.display = "none";
                let el = this.$refs.gridLayout.$children[index];
                el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
                let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

                if (mouseInGrid === true) {
                    this.$refs.gridLayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, template.h, template.w);
                    template.i = String(index);
                    template.x = this.layout[index].x;
                    template.y = this.layout[index].y;
                }
                if (mouseInGrid === false) {
                    const xPos = parseInt(((Math.floor(25 - template.w) / 2)))
                    const yPos = parseInt(((Math.floor(14 - template.h) / 2)))
                    this.$refs.gridLayout.dragEvent('dragend', 'drop', xPos, yPos, xPos, yPos);
                    this.layout = this.layout.filter(obj => obj.i !== 'drop');
                }
            }
        },
        preserveAspect() {
            this.storeChanges()
            this.updateGridItem++
        },
        dragend(template){
            let parentRect = document.getElementById('content').getBoundingClientRect();
            let mouseInGrid = false;
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true;
            }
            if (mouseInGrid === true) {
                this.$refs.gridLayout.dragEvent('dragend', 'drop', template.x, template.y, 1, 1);
                this.layout = this.layout.filter(obj => obj.i !== 'drop');
                this.layout = [{
                    x: template.x,
                    y: template.y,
                    w: template.w,
                    h: template.h,
                    i: template.i,
                }];
                this.layoutCache = []
                this.$refs.gridLayout.dragEvent('dragend', template.i, template.x,template.y,1,1);
                this.$refs.gridLayout.$children[this.layout.length].$refs.item.style.display="block";
                this.currentTab = 'Settings'
            } else {
                this.layout = [...this.layoutCache]
                this.layoutCache = []
            }
        },
        openFileExplorer(ref) {
          document.getElementById(ref).click();
        },
        onFileChanged(event) {
            if (event.target.files[0]) {
                this.imageUpload = event.target.files[0]
                const reader = new FileReader()
                reader.onload = () => {
                  this.widgetImage = reader.result
                  this.imageKey++
                }
                reader.readAsDataURL(this.imageUpload)
            } else {
                this.$message.error('Error Uploading File! Please try again.')
            } 
        },
        // async photoUpload(file, reference) {
        //   let url = await URL.createObjectURL(file);
        //   this.toDataUrl(url, (data) => {
        //     this.$API.uploadWidgetImage({
        //           name: file.name,
        //           data: data,
        //           type: file.type,
        //           reference: reference,
        //       });
        //   });
        // },
        // toDataUrl(url, callback) {
        //   var xhr = new XMLHttpRequest();
        //   xhr.onload = function () {
        //       var reader = new FileReader();
        //       reader.onloadend = function () {
        //           callback(reader.result);
        //       };
        //       reader.readAsDataURL(xhr.response);
        //   };
        //   xhr.open("GET", url);
        //   xhr.responseType = "blob";
        //   xhr.send();
        // },
        async photoUpload(file, reference) {
            let url = await URL.createObjectURL(file);
            let data = await this.toDataUrl(url);
            if(data) {
                let extension = this.$Format.getExtension(file.type, true) //The true specifies whether to return with a . or not. ex If true, returns '.png', and if false, returns 'png'
                if(extension == 'unknown') {
                    this.$message.error('Error Uploading File! Please try again.')
                    return null
                }
                return {
                    name: file.name,
                    data: data,
                    type: file.type,
                    reference: reference + extension,
                }
            } else {
                return null
            }
        },
        toDataUrl(url) {
            return new Promise((resolve, reject) => {
                var xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                    reader.readAsDataURL(xhr.response);
                };
                xhr.onerror = reject;
                xhr.open("GET", url);
                xhr.responseType = "blob";
                xhr.send();
            });
        },


    }

}
</script>

<style scoped>
* {
    --gutters: 0.1rem;
    --gutters-drag: 0.3rem;
    --toolBarHeight: 50px;
    --greyBase: #3a3a3a !important;
    --greyRaised: #4d4d4d !important;
    --greyDark: #1b1b1b !important;
    --fadedGrey: #bbbbbb !important;
    --primary: #538fc7 !important;
}

.mainGrid {
    margin: var(--gutters) var(--gutters) 0 var(--gutters);
    display: inline-flex;
    height: 100%;
    width: calc(100% - var(--toolBarHeight));
    /* background-color: #538fc7 !important; */
}
.rightBar {
    width: var(--toolBarHeight);
    margin-top: var(--gutters);
    display: inline-flex;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    background: var(--greyDark);
}
.navIcons {
    display: flex;
    margin-top: 10px;
    padding: 0 7px 0 7px;
    flex-direction: column;
}
.widget {
    background: var(--greyRaised) !important;
    margin: 0 0 0 0;
}
.widgetTitle {
    font-size: 16px;
    color: var(--fadedGrey);
    font-weight: 500;
    margin: 0 0 0 5px;
}
.widgetImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.widgetSubtitle {
    font-size: 10px;
    color: var(--fadedGrey);
    font-weight: 500;
    margin: 0 0 0 5px;
}
.grid {
    width: 100%;
    min-width: 20%;
    max-width: 100%;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    min-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    position: relative;
    overflow: hidden;
    background-color: var(--greyDark) !important;
}

.right {
    position: relative;
    width: 20%;
    min-width: 20%;
    max-width: 30%;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    min-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    overflow-y: scroll;
    overflow-x: hidden;
}

.photoClass {
    /* aspect-ratio: 16/9;  */
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content; 
    margin: 12px 0 12px 0; 
    background-color: var(--v-greyRaised-lighten1) !important; 
    border: 1px dashed #ffffff52;
}

.uploadPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 16/9; 
    height: 180px; 
    margin: 12px 0 12px 0; 
    background-color: var(--v-greyRaised-lighten1) !important; 
    border: 2px dashed #ffffff52;
}

.uploadPhoto:hover, .photoClass:hover {
    background-color: var(--v-greyRaised-lighten2) !important; 
    cursor: pointer;
}

.rightSection {
    height: 100%;
    width: 100%;
}

.templateCard {
    background: var(--greyRaised) !important;
    margin: 5px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.v-list-item__subtitle.description {
    font-size: 10px;
    margin: 5px 0 0 0;
    white-space: pre-line !important;
    overflow: visible !important;
}

.textAreaAdjust ::v-deep .v-text-field__details {
    margin-bottom: 0;
}
/* /////////////////// GRID SETTINGS START ///////////////////*/

/* Grid Placeholder */

.grid :is(.vue-grid-placeholder) {
    background: rgba(255, 255, 255, 0.322) !important;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    margin: 0px 0px 0 0;
}  


/* GridLines */
.gridLines::before {
    content: '';
    background-size: calc(100% / 25) calc(calc(100dvh - 50px) / 14);
    background-image: linear-gradient(
            to right,
            rgba(211, 211, 211, 0.151) 1px,
            transparent 1px
    ),
    linear-gradient(to bottom, rgba(211, 211, 211, 0.151) 1px, transparent 1px);
    height: calc(100dvh - 50px);
    width: calc(100% - 0px);
    position: absolute;
    background-repeat: repeat;
    margin: 0 0px 0 0px;
}

/* /////////////////// GRID SETTINGS END ///////////////////*/
    
</style>