<template>
    <div class="mainBody">
        <div class="topBar px-2">
            <v-menu bottom offset-y :close-on-content-click="false" :key="(updateFilterKey + 1)">
                <template v-slot:activator="{on, attrs}">
                    <v-chip :key="updateFilterKey" v-on="on" v-bind="attrs" style="background-color: hsla(0,0%,100%,.2) !important;border: none;" class="px-0 mr-2">
                        <v-avatar
                          style="height: 32px !important; width: 32px !important; min-width: 32px !important;"
                          v-if="selectedUser"
                          :color="selectedUser.avatar ? 'white' : 'secondary'"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-img
                                      v-on="on"
                                      v-if="selectedUser.avatar"
                                      contain
                                      :src="selectedUser.avatar"
                                    ></v-img>
                                    <v-icon v-else v-on="on">person</v-icon>
                                </template>
                                {{ selectedUser.firstname }} {{ selectedUser.surname }}
                            </v-tooltip>
                        </v-avatar>
                    </v-chip>
                </template>
                <v-card color="greyBase">
                    <v-card-text class="pa-2">
                        <v-text-field v-model="searchUsers" prepend-inner="search" dense hide-details outlined label="Search Users" style="width: 275px">
                        </v-text-field>
                        <div style="max-height: 40vh; overflow-y: scroll;">
                            <v-list dense v-if="usersFiltered.length > 0">
                                <v-list-item dense v-for="(user, index) in usersFiltered" :key="index" @click="changeUser(user)" style="width: 275px">
                                    <v-list-item-avatar>
                                        <v-avatar color="secondary" size="32">
                                            <span v-if="!user.avatar" class=" text-h7">{{$Format.avatarAbbreviation(user.firstname)}}</span>
                                            <img v-else :src="user.avatar" referrerpolicy="no-referrer" />
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{user.firstname + ' ' + user.surname}}</v-list-item-title>
                                        <v-list-item-subtitle>{{ user.emailAddress }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-menu bottom offset-y :close-on-content-click="false" :key="updateCustomerKey">
                <template v-slot:activator="{on, attrs}">
                    <v-chip v-on="on" v-bind="attrs" style="background-color: hsla(0,0%,100%,.2) !important;border: none;" class="py-0 px-0 mr-2">
                        <v-tooltip bottom v-if="filter.customerId && selectedCustomer">
                            <template v-slot:activator="{ on }">
                                <v-avatar
                                    style="height: 32px !important; width: 32px !important; min-width: 32px !important;"
                                    v-on="on"
                                    color="white"
                                >
                                  <v-img
                                    v-if="selectedCustomer.relatedOrganisation.logo"
                                    contain
                                    :src="selectedCustomer.relatedOrganisation.logo"
                                  ></v-img>
                                  <h3 v-else style="color: var(--v-greyBase-base) !important">
                                    {{ selectedCustomer.relatedOrganisation.name.charAt(0) }}
                                  </h3>
                                </v-avatar>
                            </template>
                          {{ $Format.removePtyLtd(selectedCustomer.relatedOrganisation.name) }}
                        </v-tooltip>
                        <v-icon class="mx-1" style="opacity: 0.5" v-if="!filter.customerId && !selectedCustomer">handshake</v-icon>
                        <span class="mr-1" v-if="filter.customerId && selectedCustomer && selectedCustomer.relatedOrganisation && revealFilters">
                          {{ $Format.removePtyLtd(selectedCustomer.relatedOrganisation.name) }}
                        </span>
                    </v-chip>  
                </template>
                <v-card color="greyBase">
                    <v-card-text class="pa-2">
                        <v-text-field v-model="searchCustomers" prepend-inner="search" dense hide-details outlined label="Search Customers" style="width: 275px">
                        </v-text-field>
                        <div style="max-height: 40vh; overflow-y: scroll;">
                            <v-list dense v-if="filterCustomers.length > 0">
                                <v-list-item @click="changeCustomer(null)" v-if="selectedCustomer && filter.customerId">
                                    <v-list-item-avatar>
                                        <v-icon>close</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>None</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense v-for="(customer, index) in filterCustomers" :key="index" @click="changeCustomer(customer)" style="width: 275px">
                                    <v-list-item-avatar>
                                        <v-avatar color="secondary" size="32">
                                            <span v-if="!customer.relatedOrganisation.logo" class=" text-h7">{{$Format.avatarAbbreviation(customer.relatedOrganisation.name)}}</span>
                                            <img v-else :src="customer.relatedOrganisation.logo" referrerpolicy="no-referrer" />
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{customer.relatedOrganisation.name}}</v-list-item-title>
                                        <v-list-item-subtitle v-if="customer.relatedOrganisation.alias">
                                            {{customer.relatedOrganisation.alias}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-menu bottom offset-y :close-on-content-click="false" :key="updateVesselKey">
                <template v-slot:activator="{on, attrs}">
                    <v-chip v-on="on" v-bind="attrs" style="background-color: hsla(0,0%,100%,.2) !important;border: none; " class="mr-2 px-1">
                        <v-tooltip bottom >
                            <template v-slot:activator="{on}">
                                <v-icon :style="filter.vesselVoyage ? '': {'opacity': '0.5'}" v-on="on">directions_boat</v-icon>
                            </template>
                            {{filter.vesselVoyage ? `${ filter.vesselVoyage.name + ' ' + filter.vesselVoyage.voyage }` : 'Vesel/Voyage'}}
                        </v-tooltip>
                    </v-chip>
                </template>
                <v-card color="greyBase">
                    <v-card-text class="pa-2">
                        <v-text-field v-model="searchVessels" prepend-inner="search" dense hide-details outlined label="Search Vessel/Voyage" style="width: 275px">
                        </v-text-field>
                        <div style="max-height: 40vh; overflow-y: scroll;">
                            <v-list dense v-if="filterVesselVoyage.length > 0">
                                <v-list-item @click="changeVoyage(null)" v-if="filter.vesselVoyage && filter.vesselVoyage.name">
                                    <v-list-item-avatar>
                                        <v-icon>close</v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>None</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item dense v-for="(vessel, index) in filterVesselVoyage" :key="index" @click="changeVoyage(vessel)" style="width: 275px">
                                    <v-list-item-avatar>
                                        <v-avatar color="secondary" size="32">
                                            <v-icon >directions_boat</v-icon>
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ vessel.name }} {{ vessel.voyage }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-menu bottom offset-y :close-on-content-click="false" :key="updateTeamKey">
                <template v-slot:activator="{on, attrs}">
                    <v-chip v-on="on" v-bind="attrs" class="py-1 mr-2" :class="!revealFilters ? 'px-1' : filter.teams.length > 0 ? 'pl-1 pr-0' : 'px-1'" style="background-color: hsla(0,0%,100%,.2) !important;" >
                        <v-tooltip bottom v-if="!revealFilters">
                            <template v-slot:activator="{on}">
                                <v-badge
                                    style="z-index: 200; position: fixed;"
                                    :content="filter.teams.length"
                                    :value="filter.teams.length"
                                    color="edit"
                                    overlap
                                    offset-x="-13"
                                    offset-y="-3"
                                ></v-badge>
                                    <v-icon :style="filter.teams.length == 0 ? {'opacity' : '0.5'} : ''" v-on="on">groups</v-icon>
                            </template>
                            Teams
                        </v-tooltip>
                        <v-icon v-if="revealFilters" :style="filter.teams.length == 0 ? {'opacity' : '0.5'} : {'margin-right': '15px'}">groups</v-icon>
                        <div v-if="revealFilters">
                            <v-chip v-if="filter.teams.length == teams.length" color="edit">
                                All Teams
                            </v-chip>
                            <v-chip v-else v-for="(team, index) in filter.teams" :key="index" :color="teams.find(x => x.id == team).colour" :style="filter.teams.length == 1 ? {'z-index': (100-index) } : {'margin-left': '-10px'}" style="border: 1px solid hsla(0,0%,100%,.4)" >
                                {{ teams.find(x => x.id == team).name }}
                            </v-chip>
                        </div>
                    </v-chip> 
                </template>
                <v-card color="greyBase">
                    <v-card-text class="pa-2">
                        <v-list>
                        <v-list-item v-for="(team, i) in teams" :key="i" :input-value="filter.teams.find(x => x == team.id) ? true : false" active-class=" text--accent-4" @click="addTeamToFilter(team)">
                              <v-list-item-content>
                                <v-list-item-title >{{ team.name }}</v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-icon>{{ filter.teams.find(x => x == team.id) ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
                              </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-btn icon @click="filterReveal()"><v-icon>{{ revealFilters ? 'chevron_left' : 'chevron_right' }}</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="editView = !editView"><v-icon v-if="!editView">edit</v-icon><v-icon v-if="editView">edit_off</v-icon></v-btn>
            <v-btn @click="developmentView" icon :color="tab == 'Development' ? '#538fc7' : '#bbbbbb'" v-if="['@loglive', '@dotparse'].some(x => $store.state.user.emailAddress.includes(x))">
                <v-icon>code</v-icon>
            </v-btn>
            <v-menu transition="slide-y-transition" bottom offset-y class="saveMenu" :close-on-content-click="false" :key="saveBoxOptionsKey" v-if="saveEnabled">
                <template v-slot:activator="{on, attrs}">
                    <v-btn v-on="on" v-bind="attrs" :color="'#ffffffe6'" style="color: var(--v-greyBase-base) !important;" >Save Dashboard</v-btn>
                </template>
                <v-card style="width: 20vw;">
                    <div class="pb-4">
                        <v-tabs v-model="tabValue" dense grow>
                            <v-tab href="#current" :disabled="!layoutProps.id" @click="createNewDash = false">Current</v-tab>
                            <v-tab href="#new" @click="createNewDash = true">New</v-tab>
                        </v-tabs>
                        <!--<v-chip label outlined class="mx-2" :color="!createNewDash ? 'tertiary' : ''" >Current</v-chip>
                            <v-chip label outlined class="mx-2" :color="createNewDash ? 'tertiary' : ''" >New</v-chip> -->
                        </div>
                        <v-card-text class="px-2 py-2">
                        <v-text-field v-if="!createNewDash" v-model="layoutProps.name" persistent-placeholder outlined label="Layout Name" placeholder="My Layout" hide-details dense></v-text-field>
                        <v-text-field v-if="createNewDash" v-model="newLayoutProps.name" persistent-placeholder outlined label="Layout Name" placeholder="My Layout" hide-details dense></v-text-field>
                        
                        <div class="d-flex justify-center pt-3">
                            <v-chip :disabled="!layoutProps.id" outlined :color="layoutProps.isDefault ? 'tertiary' : ''" v-if="!createNewDash" @click="layoutProps.isDefault = !layoutProps.isDefault">Default Dashboard</v-chip>
                            <v-chip :disabled="!layoutProps.id" outlined :color="newLayoutProps.isDefault ? 'tertiary' : ''" v-if="createNewDash" @click="newLayoutProps.isDefault = !newLayoutProps.isDefault">Default Dashboard</v-chip>
                        </div>
                        <v-subheader class="pl-0">
                            <v-icon small>lan</v-icon>Shared Templates
                            <v-divider class="ml-2"></v-divider>
                        </v-subheader>
                        <div style="display: flex; justify-content: space-evenly;" class="py-3">
                            <v-chip v-if="!createNewDash" outlined :color="layoutProps.private ? 'primary' : ''" @click="layoutProps.private = true"><v-icon small left>lock</v-icon>Private</v-chip>
                            <v-chip v-if="!createNewDash" outlined :color="!layoutProps.private ? 'primary' : ''" @click="layoutProps.private = false"><v-icon small left>public</v-icon>Shared</v-chip>
                            <v-chip v-if="createNewDash" outlined :color="newLayoutProps.private ? 'primary' : ''" @click="newLayoutProps.private = true"><v-icon small left>lock</v-icon>Private</v-chip>
                            <v-chip v-if="createNewDash" outlined :color="!newLayoutProps.private ? 'primary' : ''" @click="newLayoutProps.private = false"><v-icon small left>public</v-icon>Shared</v-chip>
                        </div>
                        <v-expand-transition>
                            <div v-if="createNewDash ? !newLayoutProps.private : !layoutProps.private" >
                                <v-subheader class="pl-0">
                                    <v-icon small>groups</v-icon>Teams
                                    <v-divider class="ml-2"></v-divider>
                                </v-subheader>
                                <div style="display: flex; justify-content: center; flex-wrap: wrap;">
                                    <div v-if="!createNewDash"><v-chip small @click="addTeamToLayout(team)" :color="layoutProps.teams.find(x => x == team.id) ? team.colour : ''" class="mb-1 mx-1" outlined v-for="(team, index) in teams" :key="index">{{ team.name }}</v-chip></div>
                                    <div v-if="createNewDash"><v-chip small @click="addTeamToLayout(team)" :color="newLayoutProps.teams.find(x => x == team.id) ? team.colour : ''" class="mb-1 mx-1" outlined v-for="(team, index) in teams" :key="index">{{ team.name }}</v-chip></div>
                                </div>
                            </div>
                        </v-expand-transition>
                        <div>
                            <v-subheader class="pl-0">
                                <v-icon small>sell</v-icon>Tags
                                <v-divider class="ml-2"></v-divider>
                            </v-subheader>
                            <div style="display: flex; justify-content: center; flex-wrap: wrap; ">
                               <div v-if="!createNewDash"> <v-chip small @click="addTagToLayout(tag)" :color="layoutProps.tags.find(x => x == tag) ? 'white' : ''" class="mb-1 mx-1" outlined v-for="(tag, index) in tags" :key="index">{{ tag }}</v-chip></div>
                               <div v-if="createNewDash"> <v-chip small @click="addTagToLayout(tag)" :color="newLayoutProps.tags.find(x => x == tag) ? 'white' : ''" class="mb-1 mx-1" outlined v-for="(tag, index) in tags" :key="index">{{ tag }}</v-chip></div>
                            </div>
                        </div>
                        <div style="display: flex; justify-content: center;" class="py-3">
                            <v-btn :loading="saveLayoutLoading" color="primary" v-if="createNewDash" @click="saveLayoutToUser()">Save Dashboard</v-btn>
                            <v-btn :loading="updateLayoutLoading" color="primary" v-if="!createNewDash" @click="updateLayout()">Update Dashboard</v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>
        </div>
        <v-expand-transition>
            <div v-if="revealFilters" class="filters">
                <v-subheader style="font-size: 16px">Filters</v-subheader>
                <div  class="filterBar">
                    <div id="UserFilter" class="grid_item">
                        <v-autocomplete
                          class="my-1"
                          outlined
                          dense
                          hide-details
                          :prepend-inner-icon="selectedUser.firstname ? '' : 'account_circle'"
                          :items="users"
                          item-text="firstname"
                          item-value="id"
                          v-model="filter.assignedUserId"
                          label="User"
                          @change="changeUser($event)"
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip outlined style="border: none" small>
                              <v-avatar
                                size="24"
                                left
                                :color="item.avatar ? 'white' : 'secondary'"
                              >
                                <v-img
                                  v-if="item.avatar"
                                  contain
                                  :src="item.avatar"
                                ></v-img>
                                <v-icon v-else>person</v-icon>
                              </v-avatar>
                              {{ item.firstname }} {{ item.surname }}
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-avatar>
                              <v-img
                                v-if="item.avatar"
                                :src="`${item.avatar}`"
                              ></v-img>
                              <v-icon v-else>person</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                >{{ item.firstname }}
                                {{ item.surname }}</v-list-item-title
                              >
                              <v-list-item-subtitle>
                                {{ item.emailAddress }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                    </div>
                    <div id="CustomerFilter" class="grid_item">
                        <v-autocomplete
                          class="my-1"
                          outlined
                          dense
                          hide-details
                          :prepend-inner-icon="filter.customerId ? '' : 'handshake'"
                          :items="filterCustomers"
                          item-text="relatedOrganisation.name"
                          item-value="relatedOrganisation.id"
                          v-model="filter.customerId"
                          @change="changeCustomer($event)"
                          label="Customer"
                        >
                          <template v-slot:selection="data">
                            <v-chip small class="pl-1" style="background-color: hsla(0,0%,100%,.2) !important">
                              <v-avatar
                                left
                                size="32"
                                :color="
                                  data.item && data.item.relatedOrganisation.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                                class="mr-2"
                                v-if="data.item"
                              >
                                <v-img
                                  v-if="data.item.relatedOrganisation.logo"
                                  contain
                                  :src="data.item.relatedOrganisation.logo"
                                ></v-img>
                                <h3 v-else>
                                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                                </h3>
                              </v-avatar>
                              <span v-if="data.item.relatedOrganisation">
                                {{ $Format.removePtyLtd(data.item.relatedOrganisation.name) }}
                              </span>
                            </v-chip>
                          </template>
                          <template v-slot:item="data">
                            <v-list-item-action>
                              <v-avatar
                                size="32"
                                :color="
                                  data.item.relatedOrganisation.logo
                                    ? 'white'
                                    : 'secondary'
                                "
                              >
                                <v-img
                                  contain
                                  v-if="data.item.relatedOrganisation.logo"
                                  :src="data.item.relatedOrganisation.logo"
                                ></v-img>
                                <h3 v-else>
                                  {{ data.item.relatedOrganisation.name.charAt(0) }}
                                </h3>
                              </v-avatar>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                data.item.relatedOrganisation.name
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                v-if="data.item.relatedOrganisation.alias"
                                >{{
                                  data.item.relatedOrganisation.alias
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                    </div>
                    <div id="VesselFilter" class="grid_item">
                        <v-autocomplete
                          class="my-1"
                          outlined
                          dense
                          hide-details
                          prepend-inner-icon="directions_boat"
                          :items="activeVessels"
                          item-text="name"
                          item-value="id"
                          return-object
                          v-model="filter.vesselVoyage"
                          @change="changeVoyage"
                          label="Vessel/Voyage"
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip outlined style="border: none" small>
                              {{ item.name }} {{ item.voyage }}
                            </v-chip>
                          </template>
                          <template v-slot:item="{ item }">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.name }} {{ item.voyage }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template> 
                        </v-autocomplete>
                    </div>
                    <div id="TeamsFilter" class="grid_item col_span_2">
                        <v-autocomplete
                          outlined
                          dense
                          hide-details
                          prepend-inner-icon="groups"
                          :items="teams"
                          item-text="name"
                          item-value="id"
                          v-model="filter.teams"
                          @change="filterKey++"
                          label="Teams"
                          multiple
                        >
                            <template v-slot:selection="{ item }">
                                <v-chip v-if="filter.teams.length != teams.length" class="px-1" x-small :color="item.colour" :style="filter.teams.length == 1 ? {'z-index': (100-item.id) } : {'margin-left': '-8px'}" style="border: 1px solid hsla(0,0%,100%,.4)" >
                                    {{ item.name }}
                                </v-chip>
                                <v-chip small v-else-if="item.id == teams[0].id" color="edit">All Teams</v-chip>
                            </template>
                        </v-autocomplete>
                    </div>
                    <!-- <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div>
                    <div class="grid_item"><v-select hide-details outlined dense></v-select></div> -->
                    <!-- <div class="grid_item" style="grid-column-start: 10; grid-row: 1 / span 2"><v-select hide-details outlined dense></v-select></div> -->
                </div>
            </div>
        </v-expand-transition>
        <div style="display: flex;" v-if="tab == 'Dashboard'">  
            <div class="mainArea" >
                <div class="left">
                    <div class="containers leftCard first c1" id="mainGridWrap">
                        <grid-layout :class="showGrid ? 'grid gridLines' : 'grid'"
                            ref="mainGrid"
                            id="grid"
                            :layout="layout"
                            :col-num="25"
                            :row-height="getRowHeight()"
                            :max-rows="70"
                            :responsive="false"
                            :vertical-compact="true"
                            :is-draggable="editView ? false : true"
                            :is-resizable="editView ? false : true"
                            :is-mirrored="false"
                            :margin="[0, 0]"
                            :use-css-transforms="true"
                            >
                            <grid-item 
                                v-for="(item, index) in layout"
                                :class="editView ? 'editingGrid' : 'gridCard'"
                                :x="item.x"
                                :y="item.y"
                                :w="item.w"
                                :h="item.h"
                                :i="item.i"
                                :key="updateGridItem + index"
                                :minW="item.minW"
                                :maxW="item.maxW"
                                :minH="item.minH"
                                :maxH="item.maxH"
                                :isResizable="editView ? false : item.isResizable" 
                                :preserve-aspect-ratio="item.scaleToAR"
                                @resize="layoutChanged"
                                @move="layoutChanged"
                                @resized="layoutChanged"
                            >
                                <WidgetImport :class="editView ? 'editing' : ''" :activeWeeks="activeWeeks" :widget="item.path" :w="item.w" :h="item.h" :filters="filter" :key="filterKey" :widgetIndex="index" :id="`${item.path + '-' + index}`"/>
                                <div class="overlay" v-show="editView">
                                    <v-btn @click="removeWidgetItem(index)" small class="deleteButton" color="white" fab><v-icon color="redPop">close</v-icon></v-btn>
                                </div>
                            </grid-item>
                        </grid-layout>
                    </div>
                </div>
                <div class="right" v-if="widgetWindow && widgetWindow != ''">
                    <div v-if="widgetWindow == 'Widgets'" class="rightSection">
                    <v-toolbar dense flat>
                        <v-toolbar-title>
                            Widgets
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-divider></v-divider>
                            <div v-for="(widget, index) in widgets.filter(x => x.readyForPublish)" :key="index" style="display: flex; flex-direction: column; align-items: center; width: 100%; padding: 0 5px 0 5px">
                            <div style="width: 100%; padding: 5px; display: flex; flex-direction: column;">
                                <span class="widgetTitle">{{widget.title}}</span>
                                <span class="widgetSubtitle">{{widget.description ?? ''}}</span>
                            </div>
                            <v-card :height="widget.imageSigned ? 'fit-content' : ((widget.h * 1.7) + 'rem')" :width="((widget.w * 1.7) + 'rem')" class="templateCard" style="margin: 5px 0 15px 0; contain: content"
                            @click="addWidgetItem(widget)" elevation="20"
                            >
                            <!-- THIS GOES IN THE ABOVE CARD, BUT NEEDS SOME WORK BEFORE IMPLEMENTING -->
                            <!-- @drag="drag(widget)" @dragend="dragend(widget)" draggable="true" -->
                            <v-img :src="widget.imageSigned" v-if="widget.imageSigned"></v-img>
                            <b v-else>{{ widget.h + ' x ' + widget.w}}</b>
                        </v-card>
                    </div>
                </div>
                </div>
            </div>
            <div class="rightBar">
                <div class="navIcons">
                    <div v-for="(widget, i) in tabs.filter(x => x.show )" :key="i">
                        <v-tooltip left open-delay="0" close-delay="0" transition="none">
                            <template v-slot:activator="{on: on}">
                                <v-btn plain class="pa-0 mt-1" v-on="on" small fab @click="handleWidget(widget)">
                                    <v-icon :color="widget.name == currentTool ? '#538fc7' : '#bbbbbb'">{{widget.icon}}</v-icon>
                                </v-btn>
                            </template>
                            {{widget.name}}
                        </v-tooltip>
                    </div>
                </div>
                <v-menu left offset-x transition="slide-x-reverse-transition" :close-on-content-click="false">
                    <template v-slot:activator="{on, attrs}">
                        <v-btn v-on="on" v-bind="attrs" style="position: absolute; bottom: 0; margin-left: 5px;" small fab plain color="white">
                            <v-icon>settings</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>View Grid</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-switch v-model="showGrid"></v-switch>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card>
                </v-menu>
            </div>
        </div>
        <div v-if="tab == 'Development'">
            <WidgetDevelopment :newWidget="newWidget" :filter="filter" :filterKey="filterKey"/>
        </div>
    </div>
</template>
    
<script>
// import BookingWidget from '../components/Widgets/WatchList/BookingWatchlist.vue'
import WidgetDevelopment from '../components/Dashboard/WidgetDevelopment.vue'
import VueGridLayout from 'vue-grid-layout';
import WidgetImport from '../components/Dashboard/widgetUtils/WidgetImports.vue'
let mouseXY = {"x": null, "y": null};
export default {
    components: {
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem,
        WidgetDevelopment,
        WidgetImport,
    },

    provide() {
        let filterObj = {}
        Object.defineProperties(filterObj, {
            assignedUserId: {
                enumerable: true,
                get: () => this.filter.assignedUserId
            },
            customerId: {
                enumerable: true,
                get: () => this.filter.customerId
            },
            sailingWeek: {
                enumerable: true,
                get: () => this.filter.sailingWeek
            },
            teams: {
                enumerable: true,
                get: () => this.filter.teams
            },
            vesselVoyage: {
                enumerable: true,
                get: () => this.filter.vesselVoyage
            },
        })

        return {
            filterObj
        }
    },

    data: () => ({
        showGrid: false,
        widgetWindow: '',
        newWidget: false,
        createNewDash: false,
        saveLayoutLoading: false,
        updateLayoutLoading: false,
        currentTool: '',
        updateGridItem: 12,
        updateFilterKey: 54,
        tabValue: 'current',
        updateCustomerKey: 164,
        updateVesselKey: 121,
        updateTeamKey: 241,
        tab: 'Dashboard',
        saveBoxOptionsKey: 575,
        teams: [],
        revealFilters: false,
        filter: {
          assignedUserId: null,
          customerId: null,
          sailingWeek: null,
          teams: [],
          vesselVoyage: null,
        },
        filterKey: 100,
        related: { data: [] },
        users: [],
        selectedUser: null,
        editView: false,
        searchUsers: '',
        searchCustomers: '',
        searchVessels: '',
        selectedCustomer: null,
        indexing: 828,
        saveEnabled: false,
        activeVessels: [],
        layoutDefault: null,
        activeWeeks: [],
        loadingRelatedOrganisations: false,
        layout: [],
        layoutProps: {
            name: '',
            private: true,
            teams: [],
            tags: [],
            isDefault: true,
        },
        newLayoutProps: {
            name: '',
            private: true,
            teams: [],
            tags: [],
            isDefault: false,
        },
        layoutCache: [],
        active: 'Shipments',
        tool: 'Watch-List',
        widgets: [],
        tags: ["Docs","Booking","Finance","Operations", "BusPro", "InterModal","Transport","Management"],
        tabs: [
            {
                name: "Widgets",
                icon: "widgets",
                show: true
            },
            {
                name: "Watch-List",
                icon: "view_list",
                show: false
            },
            {
                name: "Notifications",
                icon: "notifications",
                show: false
            },
            {
                name: "Messages",
                icon: "chat",
                show: false
            },
            {
                name: "Calendar",
                icon: "event",
                show: false
            },
        ]
    }),
    mounted() {
        document.addEventListener("dragover", function (e) {
            mouseXY.x = e.clientX;
            mouseXY.y = e.clientY;
        }, false);
        sessionStorage.getItem('tab') ? this.tab = sessionStorage.getItem('tab') : this.tab = 'Dashboard';
    },
    async created() {
        sessionStorage.getItem('users') ? this.users = JSON.parse(sessionStorage.getItem('users')) : null;
        if(sessionStorage.getItem('filter')) {
            this.filter = JSON.parse(sessionStorage.getItem('filter'))
            if(!this.filter.assignedUserId && this.users.length > 0) {
                this.filter.assignedUserId = this.$store.state.user.id;
                this.selectedUser = this.users.find(x => x.id == this.filter.assignedUserId)
            } else if(this.filter.assignedUserId) {
                this.selectedUser = this.users.find(x => x.id == this.filter.assignedUserId)
            } else {
                this.selectedUser = this.$store.state.user
            }
        }
        sessionStorage.getItem('layout') ? this.layout = JSON.parse(sessionStorage.getItem('layout')) : null;
        sessionStorage.getItem('revealFilters') ? this.revealFilters = JSON.parse(sessionStorage.getItem('revealFilters')) : null;
        sessionStorage.getItem('teams') ? this.teams = JSON.parse(sessionStorage.getItem('teams')) : null;
        sessionStorage.getItem('layoutDefault') ? this.layoutDefault = sessionStorage.getItem('layoutDefault') : null;

        this.getUserLayout();
        this.getWidgets();
        this.getTeams();
        this.getUsers();
        this.getActiveWeeks();
        this.getActiveVessels();
        this.getRelations();
    },
    watch: {
        'users': {
            immediate: true,
            handler(val) {
                if(this.filter.assignedUserId) {
                    let user = this.users.find(x => x.id == this.filter.assignedUserId)
                    this.selectedUser = user ? JSON.parse(JSON.stringify(user)) : null
                } else {
                    this.selectedUser = this.$store.state.user
                }
            },
        },
        'related.data': {
            immediate: true,
            handler(val) {
                if(this.filter.customerId) {
                    let customer = this.filterCustomers.find(x => x.relatedOrganisation.id == this.filter.customerId)
                    this.selectedCustomer = customer ? JSON.parse(JSON.stringify(customer)) : null
                }
            }
        },
        'layout': {
            immediate: true,
            handler(val) {
                this.checkIfLayoutChanged()
            }
        },
    },
    computed: {
        filterProvide() {
            let filter = {}
            if(Object.keys(this.filter).length > 0) {
                filter = this.filter
            }
            return filter
        },
        filterCustomers() {
            let result = this.related.data;
            if(result.length > 0) {
                if (this.filter.teams.length > 0) {
                  result = result.filter((x) =>
                  x.relationshipTeams && x.relationshipTeams.some((y) => this.filter.teams.includes(y.teamId))
                  );
                }
                if(this.searchCustomers) {
                    result = result.filter(customer => 
                        customer.relatedOrganisation.name.toLowerCase().includes(this.searchCustomers.toLowerCase()) || 
                        customer.relatedOrganisation.alias?.toLowerCase().includes(this.searchCustomers.toLowerCase())
                    );
                }
            }
          return result;
        },
        usersFiltered() {
            let users = []
            if (this.users.length > 0) {
                if (this.searchUsers) {
                    users = this.users.filter(user => 
                        user.firstname.toLowerCase().includes(this.searchUsers.toLowerCase()) || 
                        user.surname.toLowerCase().includes(this.searchUsers.toLowerCase()) || 
                        user.emailAddress.toLowerCase().includes(this.searchUsers.toLowerCase())
                    );
                } else {
                    users = this.users;
                }
            }
            return users;
        },
        filterVesselVoyage() {
            let result = this.activeVessels;
            if(result.length > 0) {
                if(this.searchVessels) {
                    result = result.filter(vessel => 
                        vessel.name.toLowerCase().includes(this.searchVessels.toLowerCase()) || 
                        vessel.voyage.toLowerCase().includes(this.searchVessels.toLowerCase())
                    );
                }
            }
            return result;
        }
    },
    methods: {
        changeUser(user) {
            if(typeof user == 'object') {
            this.selectedUser = user;
            this.filter.assignedUserId = user.id;
            } else if (typeof user == 'number') {
                let find = this.users.find(x => x.id == user)
                this.selectedUser = find
                this.filter.assignedUserId = find.id;
            }
            this.updateSession('filter', this.filter)
            this.updateFilterKey++
        },
        findEmptySpace(w, h) {
            if(this.layout.length == 0) {
                return { x: 0, y: 0 }
            }
          const gridHeight = 70;
          const gridWidth = 25;
          const isOccupied = (x, y, w, h) => {
            for (let block of this.layout) {
              if (
                x < block.x + block.w &&
                x + w > block.x &&
                y < block.y + block.h &&
                y + h > block.y
              ) {
                return true;
              }
            }
            return false;
          };
          for (let y = 0; y <= gridHeight - h; y++) {
            for (let x = 0; x <= gridWidth - w; x++) {
              if (!isOccupied(x, y, w, h)) {
                return { x, y };
              }
            }
          }
          return null;
        },
        changeCustomer(customer) {
            if(typeof customer == 'object') {
                this.selectedCustomer = customer
                this.filter.customerId = customer?.relatedOrganisation?.id ? customer.relatedOrganisation.id : null;
            } else if (typeof customer == 'number') {
                let find = this.filterCustomers.find(x => x.relatedOrganisation.id == customer)
                this.selectedCustomer = find
            }
                this.updateSession('filter', this.filter)
                this.updateCustomerKey++
        },
        changeVoyage(vesselVoyage) {
            this.filter.vesselVoyage = vesselVoyage
            this.updateSession('filter', this.filter)
            this.updateVesselKey++
        },
        addTeamToFilter(team) {
            if(this.filter.teams.includes(team.id)) {
                this.filter.teams = this.filter.teams.filter(x => x != team.id)
            } else {
                this.filter.teams.push(team.id)
            }
            this.updateSession('filter', this.filter)
        },

        createNewWidget() {
            this.newWidget = !this.newWidget
        },
        async getWidgets() {
            let result = await this.$API.getWidgets();
            this.widgets = result
        },
        handleResize(gutter) {
            const resizer = document.querySelector(gutter)
            const leftCards = document.querySelector(".left")
            const rightCards = document.querySelector(".right")
            const leftFirst = document.querySelector(".c1")
            const leftSecond = document.querySelector(".c2")
            const rightFirst = document.querySelector(".c3")
            const rightSecond = document.querySelector(".c4")
            const rightThird = document.querySelector(".c5")

            function initResize() {
                let x, y, lw, rw, lt, lb, rf, rs, rt;
                let rfMin, rfMax, rsMin, rsMax, rtMin, rtMax;

                function mouseDownHandler(e) {
                    x = e.clientX;
                    y = e.clientY;
                    let leftWidth = window.getComputedStyle(leftCards).width;
                    let rightWidth = window.getComputedStyle(rightCards).width;
                    let leftTop = window.getComputedStyle(leftFirst).height;
                    let leftBottom = window.getComputedStyle(leftSecond).height;
                    let rightOne = window.getComputedStyle(rightFirst).height;
                    let rightOneMin = window.getComputedStyle(rightFirst).minHeight;
                    let rightOneMax = window.getComputedStyle(rightFirst).maxHeight;
                    let rightTwo = window.getComputedStyle(rightSecond).height;
                    let rightTwoMin = window.getComputedStyle(rightSecond).minHeight;
                    let rightTwoMax = window.getComputedStyle(rightSecond).maxHeight;
                    let rightThree = window.getComputedStyle(rightThird).height;
                    let rightThreeMin = window.getComputedStyle(rightThird).minHeight;
                    let rightThreeMax = window.getComputedStyle(rightThird).maxHeight;
                    lw = parseInt(leftWidth)
                    rw = parseInt(rightWidth)
                    lt = parseInt(leftTop)
                    lb = parseInt(leftBottom)
                    rf = parseInt(rightOne)
                    rs = parseInt(rightTwo)
                    rt = parseInt(rightThree)

                    rfMin = parseInt(rightOneMin)
                    rfMax = parseInt(rightOneMax)
                    rsMin = parseInt(rightTwoMin)
                    rsMax = parseInt(rightTwoMax)
                    rtMin = parseInt(rightThreeMin)
                    rtMax = parseInt(rightThreeMax)

                    document.addEventListener("mousemove", mouseMoveHandler);
                    document.addEventListener("mouseup", mouseUpHandler);
                }

                function mouseMoveHandler(e) {
                    if (gutter == '.gutterMiddle') {
                        let dx = e.clientX - x;
                        let w1 = lw + dx;
                        let w2 = rw - dx;
                        if (w1 && w2) {
                            leftCards.style.width = `${w1}px`
                            rightCards.style.width = `${w2}px`
                        }
                    } else if (gutter == '.gutterLeft') {
                        let dy = e.clientY - y;
                        let h1 = lt + dy;
                        let h2 = lb - dy;
                        if (h1 && h2) {
                            leftFirst.style.height = `${h1}px`
                            leftSecond.style.height = `${h2}px`
                        }
                    } else if (gutter == '.gutterRight1') {
                        let dy = e.clientY - y;
                        let h1 = rf + dy;
                        let h2;
                        // let h2 = h1 < rfMin ? rs : rs - dy;
                        if (h1 < rfMin || h1 > rfMax) {
                            let x = document.querySelector(".c4")
                            let y = window.getComputedStyle(x).height;
                            let z = parseInt(y)
                            h2 = z
                        } else {
                            h2 = rs - dy;
                        }
                        if (h1 && h2) {
                            rightFirst.style.height = `${h1}px`
                            rightSecond.style.height = `${h2}px`
                        }
                    } else if (gutter == '.gutterRight2') {
                        let dy = e.clientY - y;
                        let h1 = rs + dy;
                        let h2;
                        if (h1 < rsMin || h1 > rsMax) {
                            let x = document.querySelector(".c5")
                            let y = window.getComputedStyle(x).height;
                            let z = parseInt(y)
                            h2 = z
                        } else {
                            h2 = rt - dy;
                        }
                        if (h1 && h2) {
                            rightSecond.style.height = `${h1}px`
                            rightThird.style.height = `${h2}px`
                        }
                    }
                }

                function mouseUpHandler() {
                    document.removeEventListener("mouseup", mouseUpHandler)
                    document.removeEventListener("mousemove", mouseMoveHandler)
                }
                resizer.addEventListener("mousedown", mouseDownHandler)
            }
            initResize()
        },
        handleWidget(widget) {
            this.currentTool == widget.name ? this.currentTool = '' : this.currentTool = widget.name
            if(widget.name == 'Development') {
                this.tab = 'Development'
            } else if(widget.name == this.widgetWindow) {
                this.widgetWindow = ''
            } else {
                this.widgetWindow = widget.name
            }
        },
        addWidgetItem(widget) {
            const position = this.findEmptySpace(widget.w, widget.h);
            if(position) {
                const xPos = position.x
                const yPos = position.y
                this.layout.push({
                ...widget,
                x: xPos,
                y: yPos,
                w: widget.w,
                h: widget.h,
                i: this.indexing * this.layout.length + 1,
            })
            this.indexing++
            } else {
                return
            }
        },
        removeWidgetItem(index) {
            this.layout.splice(index, 1)
            this.layoutChanged()
        },
        developmentView() {
            this.tab == 'Development' ? this.tab = 'Dashboard' : this.tab = 'Development'
        },
        drag(template) {
            let parentRect = document.getElementById('mainGridWrap').getBoundingClientRect();
            let mouseInGrid = false;
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true;
            }
            if (mouseInGrid === true && (this.layout.findIndex(item => item.i === 'drop')) === -1) {
                this.layoutCache = [...this.layout]
                this.layout.push({
                    ...template,
                    x: (this.layout.length * 2) % (this.colNum || 12),
                    y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                    w: 1,
                    h: 1,
                    i: 'drop',
                })
            }
            let index = this.layout.findIndex(item => item.i === 'drop');
            if (index !== -1) {
                    this.$refs.mainGrid.$children[this.layout.length].$refs.item.style.display = "none";
                let el = this.$refs.mainGrid.$children[index];
                el.dragging = {"top": mouseXY.y - parentRect.top, "left": mouseXY.x - parentRect.left};
                let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

                if (mouseInGrid === true) {
                    this.$refs.mainGrid.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, template.h, template.w);
                    template.i = String(index);
                    template.x = this.layout[index].x;
                    template.y = this.layout[index].y;
                }
                if (mouseInGrid === false) {
                    const xPos = parseInt(((Math.floor(25 - template.w) / 2)))
                    const yPos = parseInt(((Math.floor(14 - template.h) / 2)))
                    this.$refs.mainGrid.dragEvent('dragend', 'drop', xPos, yPos, xPos, yPos);
                    this.layout = this.layout.filter(obj => obj.i !== 'drop');
                }
            }
        },
        dragend(template){
            let parentRect = document.getElementById('mainGridWrap').getBoundingClientRect();
            let mouseInGrid = false;
            if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right)) && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
                mouseInGrid = true;
            }
            if (mouseInGrid === true) {
                this.$refs.mainGrid.dragEvent('dragend', 'drop', template.x, template.y, 1, 1);
                this.layout = this.layout.filter(obj => obj.i !== 'drop');
                this.layout.push({
                    ...template,
                    x: template.x,
                    y: template.y,
                    w: template.w,
                    h: template.h,
                    i: this.indexing * this.layout.length + 1,
                })
                this.layoutCache = []
                this.$refs.mainGrid.dragEvent('dragend', template.i, template.x,template.y,1,1);
                this.$refs.mainGrid.$children[this.layout.length].$refs.item.style.display="block";
                sessionStorage.setItem('layout', JSON.stringify(this.layout))
            } else {
                this.layout = [...this.layoutCache]
                this.layoutCache = []
                sessionStorage.setItem('layout', JSON.stringify(this.layout))
            }
        },
        //TOOLS

        getRowHeight() {
            let rowHeight = (window.innerHeight - 50) / 14;
            // convert to integer
            rowHeight = parseFloat(rowHeight.toFixed(2))
            return rowHeight;
        },
        updateSession(type, data) {
            if(type == 'filter.customerId') {
                this.updateCustomer()
                sessionStorage.setItem('filter', JSON.stringify(data))
            } else {
                sessionStorage.setItem(type, JSON.stringify(data))
                this.filterKey++
            }
        },
        filterReveal() {
            this.revealFilters = !this.revealFilters;
            sessionStorage.setItem('revealFilters', JSON.stringify(this.revealFilters))
        },
        layoutChanged() {
            sessionStorage.setItem('layout', JSON.stringify(this.layout))
            this.checkIfLayoutChanged()
        },
        checkIfLayoutChanged() {
            let layout = JSON.stringify(this.layout)
            if(!this.layoutDefault) {
                if(this.layout.length > 0) {
                    this.saveEnabled = true
                } else {
                    this.saveEnabled = false
                }
            } else if(typeof this.layoutDefault != 'string') {
                this.layoutDefault = JSON.stringify(this.layoutDefault)
                if(layout != this.layoutDefault) {
                    this.saveEnabled = true
                } else {
                    this.saveEnabled = false
                }
            } else {
                if(layout != this.layoutDefault) {
                    this.saveEnabled = true
                } else {
                    this.saveEnabled = false
                }
            }
        },
        addTeamToLayout(team) {
            if(!this.createNewDash) {
                if(this.layoutProps.teams.includes(team.id)) {
                    this.layoutProps.teams = this.layoutProps.teams.filter(x => x != team.id)
                } else {
                    this.layoutProps.teams.push(team.id)       
                }
            } else {
                if(this.newLayoutProps.teams.includes(team.id)) {
                    this.newLayoutProps.teams = this.newLayoutProps.teams.filter(x => x != team.id)
                } else {
                    this.newLayoutProps.teams.push(team.id)       
                }
            }
        },
        addTagToLayout(tag) {
            if(!this.createNewDash) {
                if(this.layoutProps.tags.includes(tag)) {
                    this.layoutProps.tags = this.layoutProps.tags.filter(x => x != tag)
                } else {
                    this.layoutProps.tags.push(tag)
                }
            } else {
                if(this.newLayoutProps.tags.includes(tag)) {
                    this.newLayoutProps.tags = this.newLayoutProps.tags.filter(x => x != tag)
                } else {
                    this.newLayoutProps.tags.push(tag)
                }
            }
        },
        //Data Queries
        async saveLayoutToUser() {
            this.saveLayoutLoading = true;
            let obj = {
                layout: this.layout,
                layoutProps: this.newLayoutProps
            }
            let result = await this.$API.saveLayout(obj);
            if(result) {
                this.$message.success('Layout Saved')
                this.layoutProps = {
                    id: result.id,
                    name: result.name,
                    private: result.private,
                    teams: JSON.parse(result.teams),
                    tags: JSON.parse(result.tags),
                    isDefault: result.isDefault
                }
                this.createNewDash = false;
                console.log(result)
            }
            this.saveLayoutLoading = false;

        },
        async updateLayout() {
            this.updateLayoutLoading = true;
            let obj = {
                layout: this.layout,
                layoutProps: this.layoutProps
            }
            let result = await this.$API.updateLayoutToUser(obj);
            if(result) {
                this.$message.success('Layout Updated')
            } else {
                this.$message.error('Error Updating Layout')
            }
            this.updateLayoutLoading = false;
        },
        async getUserLayout() {
            let result = await this.$API.getDashboardLayout();
            if(result) {
                this.layout = JSON.parse(result.layout)
                this.layoutProps = {
                    id: result.id,
                    name: result.name,
                    private: result.private,
                    teams: JSON.parse(result.teams),
                    tags: JSON.parse(result.tags),
                    isDefault: result.isDefault
                }
                console.log(JSON.parse(result.layout))
                this.layoutDefault = result.layout
                sessionStorage.setItem('layoutDefault', JSON.stringify(this.layoutDefault))
            } else {
                this.createNewDash = true;
                this.newLayoutProps.isDefault = true;
                sessionStorage.getItem('layout') ? this.layout = JSON.parse(sessionStorage.getItem('layout')) : null;
            }
        },
        async getTeams() {
          try {
            let result = await this.$API.getTeams();
            this.teams = result;
            sessionStorage.setItem('teams', JSON.stringify(this.teams))

          } catch (error) {
            console.log(error);
          }
        },
        async getActiveWeeks() {
            this.activeWeeks = await this.$API.getActiveWeeks();
          },
        async getActiveVessels() {
          this.activeVessels = await this.$API.getActiveVessels();
        },
        async getRelations() {
          this.loadingRelatedOrganisations = true;
          if (
            localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
          ) {
            this.related = JSON.parse(
              localStorage[
                "relatedOrganisations_" + this.$store.state.currentOrg.id
              ]
            );
          }
          this.related = await this.$API.getRelation({});
          localStorage.setItem(
            "relatedOrganisations_" + this.$store.state.currentOrg.id,
            JSON.stringify(this.related)
          );
          this.loadingRelatedOrganisations = false;
        },
        async getUsers() {
          let result = await this.$API.getOrganisationUsers();
          result = result.map((x) => ({ ...x.user, position: x.position }));
          result.sort((a, b) => a.firstname.localeCompare(b.firstname));
          this.users = result;
          sessionStorage.setItem('users', JSON.stringify(this.users))
        },
        updateCustomer() {
            let result = this.filterCustomers.find(x => x.relatedOrganisation.id == this.filter.customerId)
            this.selectedCustomer = result ? JSON.parse(JSON.stringify(result)) : null
            console.log(this.selectedCustomer)
        }
    }
}
</script>

<style scoped>
* {
    --gutters: 0.1rem;
    --gutters-drag: 0.3rem;
    --toolBarHeight: 50px;
    --greyBase: #3a3a3a !important;
    --greyRaised: #4d4d4d !important;
    --greyDark: #1b1b1b !important;
    --fadedGrey: #bbbbbb !important;
    --primary: #538fc7 !important;
}

/* /////////////////// GRID SETTINGS START ///////////////////*/

/* Grid Placeholder */

.grid :is(.vue-grid-placeholder) {
    background: #729fd9 !important;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    margin: 0px 0px 0 0;
}  


/* GridLines */
.gridLines::before {
    content: '';
    background-size: calc(100% / 25) calc(calc(100dvh - 50px) / 14);
    background-image: linear-gradient(
            to right,
            rgba(211, 211, 211, 0.151) 1px,
            transparent 1px
    ),
    linear-gradient(to bottom, rgba(211, 211, 211, 0.151) 1px, transparent 1px);
    min-height: calc(100dvh - 50px);
    height: inherit;
    width: calc(100% - 0px);
    position: absolute;
    background-repeat: repeat;
    margin: 0 0px 0 0px;
}
.grid {
    width: 100%;
    min-width: 20%;
    max-width: 100%;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    max-height: calc(5 * (100dvh - var(--toolBarHeight) - var(--gutters) * 1));
    min-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    position: relative;
    overflow-y: scroll;
    background-color: #0d1216 !important;
    z-index: 1;
}
.overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #538fc7;
    background-color: rgba(0, 0, 0, 0.4); /* adjust color and opacity as needed */
    z-index: 1000; /* make sure it's above everything else */
}
.overlay .deleteButton {
    opacity: 0;
    z-index: 2000 !important;
    transition: opacity 0.3s ease;
}

.overlay:hover .deleteButton {
    opacity: 1;
}

.overlay:hover {
    background-color: #538fc7a9 !important; 
}

.editing > *{
    pointer-events: none;
    z-index: 0;
}


/* /////////////////// GRID SETTINGS END ///////////////////*/

.navIcons {
    display: flex;
    margin-top: 10px;
    padding: 0 7px 0 7px;
    flex-direction: column;
}
.rightSection {
    height: 100%;
    width: 100%;
}
.widgetTitle {
    font-size: 16px;
    color: var(--fadedGrey);
    font-weight: 500;
    margin: 0 0 0 5px;
}
.widgetImage {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.templateCard {
    background: var(--greyRaised) !important;
    margin: 5px 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.widgetSubtitle {
    font-size: 10px;
    color: var(--fadedGrey);
    font-weight: 500;
    margin: 0 0 0 5px;
}
.containers {
    overflow: scroll;
}

.topBar {
    width: 100%;
    height: var(--toolBarHeight);
    background: var(--v-greyBase-base) !important;
    display: flex;
    align-items: center;
}
.filterBar {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: 1fr;
    contain: content;
}
.filters {
    width: 100%;
    height: auto;
    background: var(--v-greyBase-base) !important;
}
.grid_item {
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px 0 4px;
}

.col_span_2 {
    grid-column: span 2;
}

.leftBar {
    width: var(--toolBarHeight);
    margin-top: var(--gutters);
    display: inline-flex;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    background: var(--greyDark);
}

.rightBar {
    width: var(--toolBarHeight);
    display: inline-flex;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    background: var(--v-greyRaised-base) !important;
}

.mainBody {
    padding: 0;
    background: var(--greyBase);
    user-select: none;
    /* display: flex; */
}

.mainArea {
    margin: 0;
    display: inline-flex;
    height: auto;
    width: calc(100% - var(--toolBarHeight));
}

.left {
    width: 100%;
    min-width: 20%;
    max-width: 100%;
    height: calc(100dvh - var(--toolBarHeight));
    max-height: calc(100dvh - var(--toolBarHeight));
    min-height: calc(100dvh - var(--toolBarHeight));
    position: relative;
    overflow: hidden;
}

h1 {
    color: #ffffff;
    font-size: 2rem;
    padding: 1rem
}

.menuCard {
    height: auto;
    width: 10vw;
    margin-left: 10px;
}

.menuCard :is(.v-list, .v-list-item__title) {
    background: var(--greyRaised) !important;
    color: white !important;
}

.bottomTabs :is(.v-tabs, .v-tabs-bar, .v-tab) {
    background-color: var(--greyDark) !important;
    color: var(--fadedGrey) !important;
}

.bottomTabs :is(.v-tab--active) {
    color: var(--primary) !important;
}

.c1 {
    
}

.right {
    position: relative;
    width: 20%;
    min-width: 20%;
    max-width: 30%;
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    min-height: calc(100dvh - var(--toolBarHeight) - var(--gutters) * 1);
    overflow-x: hidden;
    overflow-y: scroll;
}

.leftCard,
.rightCard {
    flex: 1;
    background: var(--greyDark);
}

.leftCard.first {
    height: 100%;
    min-height: 0;
    max-height: 100%;
}

.gridCard {
    background: transparent !important;
    margin: 0 0 0 0;
    contain: content;
}
.leftCard.second {
    height: 5%;
    min-height: 5%;
    max-height: 100%;
}

.rightCard.first {
    height: 50%;
    min-height: 3rem;
    max-height: calc(90dvh - var(--toolBarHeight));
    ;
}

.rightCard.second {
    height: 30%;
    min-height: 3rem;
    max-height: calc(90dvh - var(--toolBarHeight));
    ;
}

.rightCard.third {
    height: 20%;
    min-height: 3rem;
    max-height: calc(90dvh - var(--toolBarHeight));
    ;
}

.logo {
    margin: 0 0 0 0;
    letter-spacing: 4px;
    font-size: 20px;
    font: 24px/26px Roboto, Helvetica, sans-serif;
    color: #fff;
    cursor: pointer;
}

.gutterLeft,
.gutterRight1,
.gutterRight2 {
    height: var(--gutters-drag);
    background: var(--greyBase);
    width: 100%;
}

.gutterMiddle {
    width: var(--gutters-drag);
    background: var(--greyBase);
    height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
    max-height: calc(100dvh - var(--toolBarHeight) - var(--gutters));
}

.gutterLeft:hover,
.gutterRight1:hover,
.gutterRight2:hover {
    background: var(--greyRaised);
    cursor: row-resize;
    height: calc(var(--gutters-drag) + 5px) !important;
}

.gutterMiddle:hover {
    background: var(--greyRaised);
    cursor: col-resize;
    width: calc(var(--gutters-drag) + 5px);

}
.saveMenu :is(.v-menu__content) {
    right: 50px;
}
</style>