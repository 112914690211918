<template>
    <div>
        <v-card>
            <v-toolbar flat dense>
                Creating a Widget
            </v-toolbar>
            <v-card flat>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <h2>Step One: Create the Widget File</h2>
                            <p>Create a new file in 
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                            <code v-on="on">/components/Dashboard/widgets/</code>
                                    </template>
                                    <v-card>
                                    <v-img src="../../../assets/StepOne.png" width="35vh"></v-img>
                                </v-card>
                                </v-tooltip> 
                                with the name <code>{{ widget.path }}.vue</code> 
                                    <v-btn @click="copyCode(`${widget.path}.vue`), copyName = !copyName" icon small plain class="pa-0">
                                        <v-icon small>content_copy</v-icon>
                                    </v-btn> 
                                    <v-chip small label :class="{ show: copyName }" class="quickTooltip">Copied!</v-chip>
                                </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2>Step Two: Import Your Widget</h2>
                            <p>In the <code>WidgetImports.vue</code> component, found in 
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                            <code v-on="on">/components/Dashboard/widgetUtils/WidgetImports.vue </code>
                                    </template>
                                    <v-card>
                                        <v-img src="../../../assets/StepTwo.png" width="35vh"></v-img>
                                    </v-card>
                                </v-tooltip>, add the following lines in the <code v-html="highlight('<script>', 'xml')"></code> tag: </p>
                        </v-col>
                    </v-row>
                    <v-card style="background-color: #343434 !important;" flat>
                        <v-card-text class="py-2">
                            <span class="codeDull">&lt;script&gt;<br>...</span><br>
                            <span class="codeDull">import ExampleWidget from '../widgets/ExampleWidget.vue'</span>
                            <pre><code><span v-html="highlight(stepOne, 'javascript')"></span></code><v-btn small plain class="ml-3 pa-0" style="height: 14px;" @click="copyCode(stepOne), copyStep1 = !copyStep1"><v-icon v-if="!copyStep1" x-small>content_copy</v-icon>{{copyStep1 ? 'Copied!' : 'Copy'}}</v-btn></pre><span class="codeDull">...<br>export default {</span><br>
                            <span class="ml-7 codeDull" >components: {</span>
                            <pre><code class="ml-12 importName" v-html="highlight(widget.path, 'javascript')"></code><v-btn small plain class="ml-3 pa-0" style="height: 14px;" @click="copyCode(widget.path), copyStep2 = !copyStep2"><v-icon v-if="!copyStep2" x-small>content_copy</v-icon>{{copyStep2 ? 'Copied!' : 'Copy'}}</v-btn></pre>
                        </v-card-text>
                    </v-card>
                    <v-row class="my-2">
                        <v-col>
                            Then, add the following line in the <code v-html="highlight('<template>', 'xml')"></code> section:
                        </v-col>
                    </v-row>
                    <v-card style="background-color: #343434 !important;" flat>
                        <v-card-text class="py-2">
                            <span class="codeDull">&lt;template&gt;<br><span class="ml-7">&lt;div&gt;</span></span><br>
                            <span class="codeDull ml-12">&lt;ExampleWidget v-if=&quot;widget == 'ExampleWidget'&quot;/&gt;</span>
                            <pre><code class="ml-12"><span v-html="highlight(stepThree, 'xml')"></span></code><v-btn small plain class="ml-3 pa-0" style="height: 14px;" @click="copyCode(stepThree), copyStep3 = !copyStep3"><v-icon v-if="!copyStep3" x-small>content_copy</v-icon>{{copyStep3 ? 'Copied!' : 'Copy'}}</v-btn></pre>
                            <span class="codeDull ml-12">...</span>
                        </v-card-text>
                    </v-card>
                    <v-row class="my-2">
                        <v-col>
                            <h2>Step Three: Copy The Factory Code</h2>
                            <b>IMPORTANT: Do NOT edit the factory templates. Simply just copy their contents into your own file.</b><br>
                            <p>Factories are templates of code used to help you develop your widgets easier. Copy all of the code in your chosen template into <code>{{ widget.path + '.vue'}}</code>. From here you can edit the file however you desire.</p>
                            <p>These Factory templates can be found in
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                            <code v-on="on">/components/Dashboard/Factories </code>
                                    </template>
                                    <v-card>
                                        <v-img src="../../../assets/StepThree.png" width="35vh"></v-img>
                                    </v-card>
                                </v-tooltip></p>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="d-flex justify-center align-center">
                            <v-btn color="edit" @click="createWidget">Create Template</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-card>
    </div>
</template>

<script>
import hljs from 'highlight.js'
import javascript from 'highlight.js/lib/languages/javascript';
import xml from 'highlight.js/lib/languages/xml';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('xml', xml);
import '../../../assets/zenon.css';
export default {
    props: [
        'widget'
    ],
    data: () => ({
        stepOne: `import #REPLACE# from '../widgets/#REPLACE#.vue'`,
        stepThree: `<#REPLACE# v-if="widget == '#REPLACE#'" :columns="w" :rows="h" :widget="widget" class="widget"/>`,
        copyName: false,
        copyStep1: false,
        copyStep2: false,
        copyStep3: false
        
    }),
    watch: {
        copyName(val) {
            if(val) {
                this.switchCopy('copyName')
            }
        },
        copyStep1(val) {
            if(val) {
                this.switchCopy('copyStep1')
            }
        },
        copyStep2(val) {
            if(val) {
                this.switchCopy('copyStep2')
            }
        },
        copyStep3(val) {
            if(val) {
                this.switchCopy('copyStep3')
            }
        }
    },
    methods: {
        createWidget() {
            this.$emit('createWidget')
        },
        switchCopy(val) {
            setTimeout(() => {
                    this[val] = false
                }, 2000)
        },
        
        highlight(code, lang) {
            if(code.includes('#REPLACE#')) {
                code = this.replaceCode(code)
            }
            return hljs.highlight(code, {language: lang}).value
        },
        copyCode(code) {
            if(code.includes('#REPLACE#')) {
                code = this.replaceCode(code)
            }
            navigator.clipboard.writeText(code)
        },
        replaceCode(code) {
            code = code.replace(/#REPLACE#/g, this.widget.path)
            return code
        }
    }
}
</script>

<style scoped>

.quickTooltip {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.quickTooltip.show {
    opacity: 1;
}

.codeDull {
    font-family: monospace,monospace;
    opacity: 0.4;
    font-size: 85%;
    font-weight: 400;
}

.importName {
    color: #ffa047 !important;
}

</style>